import {
  fetchMyDetails,
  getBankAccountsList,
  getAdditionalAddressesList,
} from '@/api/me';
import { v4 as uuidv4 } from "uuid";

const init = async ({ state, commit, dispatch }) => {
  if (state.ready) {
    return;
  }

  await dispatch('fetchUserDetails');
  await dispatch('account/init', null, { root: true });

  commit('SET_READY', true);
};

const logout = async ({ commit }) => {
  commit('CLEAR');
};

const fetchUserDetails = async ({ commit, getters }) => {
  try {
    const userData = await fetchMyDetails();

    commit('SET_ROLES', userData.authData.roles || []);

    const user = Object.assign(
      {},
      userData,
    )

    if (getters.isGranted('ROLE_BUSINESS_USER')) {
      const { birthDate } = userData.authData;
      const { myCompanies, currentCompanyId } = userData;
      const { taxOffice, incomeTaxVat, incomeTaxForm, incomeTax, accountingType } = userData.companyData;

      const taxationFields = {
        taxOffice,
        incomeTaxVat,
        incomeTaxForm,
        incomeTax,
        accountingType
      }
      const shouldDisplayAccountingInfo = Object.values(taxationFields).some(field => field === null || field === '');
      const bankAccounts = await getBankAccountsList();
      const additionalPlacesOfBusiness = await getAdditionalAddressesList();

      Object.assign(
        user,
        {
          bankAccounts,
          additionalPlacesOfBusiness,
          myCompanies,
          currentCompanyId,
        }
      )

      const userDefaultAddress = {
        id: uuidv4(),
        nip: user.companyData.nip,
        companyName: user.companyData.name,
        street: user.companyData.street,
        houseNumber: user.companyData.houseNumber,
        apartmentNumber: user.companyData.apartmentNumber,
        postcode: user.companyData.postcode,
        city: user.companyData.city,
      };

      commit('account/SET_USER_DEFAULT_ADDRESS', userDefaultAddress, { root: true })
      commit('ui/SET_ACCOUNTING_INFO', shouldDisplayAccountingInfo, { root: true });
      commit('ui/SET_AUTH_INFO', birthDate === null || birthDate === '', { root: true });
    }

    commit('account/SET_USER', user, { root: true });
    commit('SET_USER', user);
    commit('SET_TOKEN_ROLES');

  } catch (error) {
    console.log(error);
  }
};

export default {
  init,
  logout,
  fetchUserDetails
}
