<template>
  <v-app :style="{ background: $vuetify.theme.themes[mode].background }">
    <!-- Layout component -->
    <template v-if="isRouterLoaded">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </template>

    <notifications>
      <template v-slot:body="{ item, close }">
        <notification-alert @click="handleNotificationClick(item.data.notificationId)">
          <h3 class="mb-1 line-clamp break-word">{{ item.title }}</h3>
          <div class="line-clamp">
            <p v-html="item.text"></p>
          </div>
        </notification-alert>
      </template>
    </notifications>

    <v-snackbar
      v-model="toast.show"
      v-bind="toast.props"
      :data-cy="`alert-${toast.color}`"
      :timeout="toast.timeout"
      :dark="mode === themeMode.DARK"
      :color="toast.color"
      top
    >
      {{ toast.message }}
    </v-snackbar>
    <v-overlay :value="loader.show" z-index="8">
      <div class="d-flex flex-column align-center justify-center">
        <v-progress-circular
          indeterminate
          :size="loaderSpinnerSize"
        />
        <v-subheader
          class="subtitle-1"
          v-if="loader.message"
        >
          {{ loader.message }}
        </v-subheader>
      </div>
    </v-overlay>

  </v-app>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
  import { MODE as themeMode } from '@/constants/theme'
  import config from './configs'
  import NotificationsMixin from "@/mixins/notifications";
  import NotificationAlert from '@/components/notifications/NotificationAlert';
  import { EventSourcePolyfill } from 'event-source-polyfill';
  import { checkIsIOS, addMaximumScaleToMetaViewport } from "@/utilities/meta";
  import { emitPaymentStatusChange } from "@/utils/event-bus";
  import download from "downloadjs";
import {useFavicon, useTitle} from "@vueuse/core";

  export default {
    mixins: [
      NotificationsMixin
    ],
    components: {
      NotificationAlert,
    },
    head: {
      link: [
        // adds config/icons into the html head tag
        ...config.icons.map(href => ({ rel: 'stylesheet', href }))
      ]
    },
    data() {
      return {
        loading: true,
        fatalError: null,
        eventSource: null,
        unreadNotifications: null,
        notificationVisible: false,
        notification: null,
        themeMode,
        isBrowserTabVisible: true,
      };
    },
    created() {
      let self = this;

      useFavicon(process.env.VUE_APP_FAVICON);
      useTitle(process.env.VUE_APP_TITLE);

      if (checkIsIOS()) {
        addMaximumScaleToMetaViewport();
      }

      document.addEventListener("visibilitychange", function() {
        self.isBrowserTabVisible = !document.hidden;
      });

      const triggerUserNotificationInitialization = this.$watch('userId', function(userId) {
        if (!userId) return;
        if (this.$isGrantedAdminOr('ROLE_OFFICE_WORKER')) {
          this.initDownloadFileNotification(userId);
          this.initNotifications(userId); // aby działały notyfikacje per user np. admin/księgowa
          triggerUserNotificationInitialization();
        }
      });

      const triggerCompanyNotificationInitialization = this.$watch('companyId', function(companyId) {
        if (!companyId) return;

        this.initPaymentNotification(companyId);
        this.initNotifications(companyId); // aby działały notyfikacje per company
        triggerCompanyNotificationInitialization();
      });

      this.initApplication();
    },
    beforeDestroy() {
      this.eventSource.close();
    },
    computed: {
      ...mapState({
        userId: state => state.account.user?.authData?.id,
        companyId: state => state.account.user?.companyData?.id,
      }),
      ...mapGetters({
        mode: 'account/mode',
        toast: 'app/toast',
        loader: 'app/loader',
      }),
      isRouterLoaded: function() {
        return this.$route.name !== null;
      },
      loaderSpinnerSize() {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 64;
        }
        return 48;
      },
      isCurrentTab () {
        return this.isBrowserTabVisible;
      }
    },
    methods: {
      ...mapActions({
        hideLoader: 'app/hideLoader',
      }),
      async initApplication() {
        const { $store } = this;

        try {
          await $store.dispatch('app/init');
        } catch (error) {
          this.fatalError =
            'An fatal error occured during application initialization! Contact system administrator.';
          throw error;
        } finally {
          this.loading = false;
        }
      },
      initNotifications(userId) {
        const url = new URL('/.well-known/mercure', process.env.VUE_APP_MERCURE_HOST);
        url.searchParams.append('topic', userId);

        this.eventSource = new EventSourcePolyfill(url, {
          headers: {
            'Authorization': 'Bearer ' + process.env.VUE_APP_MERCURE_JWT_KEY,
          }
        });
        this.eventSource.onmessage = event => {
          const data = JSON.parse(event.data);
          this.notification = data;

          if(this.companyId) {
            this.$store.commit('account/SET_COMPANY_UNREAD_NOTIFICATIONS_COUNT', data.unreadNotifications)
          } else {
            this.$store.commit('account/SET_UNREAD_NOTIFICATIONS_COUNT', data.unreadNotifications)
          }

          this.$notify({
            title: data.subject,
            text: data.content,
            data: {
              notificationId: data.notificationId,
            },
          });
        };
      },
      initPaymentNotification(companyId) {
        const url = new URL('/.well-known/mercure', process.env.VUE_APP_MERCURE_HOST);
        url.searchParams.append('topic', `${companyId}/payment/updated`);

        this.eventSource = new EventSourcePolyfill(url, {
          headers: {
            'Authorization': 'Bearer ' + process.env.VUE_APP_MERCURE_JWT_KEY,
          }
        });
        this.eventSource.onmessage = async event => {
          await this.$store.dispatch('auth/fetchUserDetails');
          emitPaymentStatusChange()
        };
      },
      initDownloadFileNotification(userId) {
        const url = new URL('/.well-known/mercure', process.env.VUE_APP_MERCURE_HOST);
        url.searchParams.append('topic', `${userId}/file/download`);

        this.eventSource = new EventSourcePolyfill(url, {
          headers: {
            'Authorization': 'Bearer ' + process.env.VUE_APP_MERCURE_JWT_KEY,
          }
        });
        this.eventSource.onmessage = async event => {
          const data = JSON.parse(event.data);
          if (data.message) {
            await this.$store.dispatch('app/showError', { message: data.message });
            await this.hideLoader();
            return;
          }

            if (this.isCurrentTab) {
              fetch(data.url)
                .then(res => res.blob())
                .then(blob => {
                  download(blob, data.filename)
                }).finally(() => {
                this.hideLoader();
              })
            }
        };
      },
    },
  }
</script>

<style lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 0.2s;
    transition-property: opacity;
    transition-timing-function: ease;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .theme--dark .v-menu__content {
    background-color: #424242;
  }
  .theme--light .v-menu__content {
    background-color: #ffffff;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .grid-actions-row {
    gap: .5rem;
  }
  .line-clamp {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .vue-notification-group {
    width: 80%!important;
    top: 10px !important;
    right: 10px !important;
    @media (min-width: 600px) {
      width: auto !important;
      min-width: 500px;
    }
  }
</style>
