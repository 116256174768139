export default {
  data() {
    return {
      navItems: [
        {
          icon: 'mdi-account-outline',
          key: '$menu.myProfile',
          text: 'My profile',
          perm: 'ROLE_BUSINESS_USER',
          exact: false,
          items: [
            {
              key: '$menu.auth',
              text: 'Edit profile',
              routeName: 'profile-user',
              perm: 'ROLE_BUSINESS_USER',
              exact: true
            },
            {
              key: '$menu.companyData',
              text: 'Edit profile',
              routeName: 'profile-user-company-data',
              perm: 'ROLE_BUSINESS_USER',
              exact: true
            },
            {
              key: '$menu.bankingData',
              text: 'Edit profile',
              routeName: 'profile-user-banking-data',
              perm: 'ROLE_BUSINESS_USER',
              exact: true
            },
            {
              key: '$menu.invoiceNumerationSchemas',
              text: 'Invoice numeration schemas',
              routeName: 'invoice-numeration-schemas-user',
              perm: 'PERM_BUSINESS_USER_INVOICE_NUMERATION_SCHEMA_LIST',
              exact: true
            },
            {
              key: '$menu.contactData',
              text: 'Edit profile',
              routeName: 'profile-user-contact-data',
              perm: 'ROLE_BUSINESS_USER',
              exact: true
            },
            {
              key: '$menu.payments',
              text: 'Payments',
              routeName: 'payments-user',
              perm: 'PERM_BUSINESS_USER_PAYMENTS',
              exact: true
            },
            {
              key: '$menu.settings',
              text: 'Settings',
              routeName: 'settings-user',
              perm: 'ROLE_BUSINESS_USER',
              exact: true
            },
          ]
        },
        {
          icon: 'mdi-account-outline',
          key: '$menu.myProfile',
          text: 'My profile',
          routeName: 'profile-admin',
          perm: ['ROLE_ADMIN', 'ROLE_OFFICE_WORKER'],
          exact: true
        },
        //COMMON
        {
          icon: 'mdi-newspaper-variant-outline',
          key: '$menu.information',
          text: 'Information',
          routeName: 'dashboard',
          perm: 'ROLE_USER',
          exact: true
        },
        // ADMIN
        {
          icon: 'mdi-account-group-outline',
          key: '$menu.users',
          text: 'Users',
          perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_COMPANY_LIST', 'PERM_OFFICE_WORKER_CLIENT_LIST', 'PERM_OFFICE_WORKER_OFFICE_WORKER_LIST', 'PERM_OFFICE_WORKER_PERMISSIONS_LIST'],
          items: [
            {
              key: '$menu.companies',
              text: 'Companies',
              routeName: 'companies-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_COMPANY_LIST'],
              exact:  true
            },
            {
              key: '$menu.clients',
              text: 'Users',
              routeName: 'clients-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_CLIENT_LIST'],
              exact:  true
            },
            {
              key: '$menu.officeWorkers',
              text: 'Office Workers',
              routeName: 'office-workers-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_OFFICE_WORKER_LIST'],
              exact:  true
            },
            {
              key: '$menu.permissions',
              text: 'Permissions',
              routeName: 'permissions-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_PERMISSIONS_LIST'],
              exact:  true
            },
          ]
        },
        {
          icon: 'mdi-chart-pie',
          key: '$menu.statistics',
          text: 'Statistics',
          routeName: 'statistics-admin',
          perm: 'NIEGOTOWE',
          exact: true
        },
        {
          icon: 'mdi-calendar-check-outline',
          key: '$menu.myAccounting',
          text: 'My accounting',
          routeName: 'my-accounting-admin',
          perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_MY_ACCOUNTING_LIST'],
          exact: true
        },
        {
          icon: 'mdi-ticket',
          key: '$menu.ticketCenterAdmin',
          text: 'Ticket Center (Q&A)',
          routeName: 'ticket-center-admin',
          perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_TICKET_CENTER_LIST'],
        },
        {
          icon: 'mdi-bell-outline',
          key: '$menu.$notifications.title',
          text: 'Notifications',
          perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_EMAIL_TEMPLATE_LIST', 'PERM_OFFICE_WORKER_WEB_TEMPLATE_LIST', 'PERM_OFFICE_WORKER_WEB_TEMPLATE_SEND', 'PERM_OFFICE_WORKER_EMAIL_TEMPLATE_SEND'],
          items: [
            {
              key: '$menu.$notifications.emailTemplates',
              text: 'E-mail templates',
              routeName: 'email-templates-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_EMAIL_TEMPLATE_LIST'],
              exact: true
            },
            {
              key: '$menu.$notifications.webNotificationTemplates',
              text: 'Web notification templates',
              routeName: 'web-notification-templates-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_WEB_TEMPLATE_LIST'],
              exact: true
            },
            {
              key: '$menu.$notifications.sendWebNotification',
              text: 'Send web notification',
              routeName: 'send-web-notification-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_WEB_TEMPLATE_SEND'],
              exact: true
            },
            {
              key: '$menu.$notifications.sendEmailMessage',
              text: 'Send e-mail message',
              routeName: 'send-email-notification-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_EMAIL_TEMPLATE_SEND'],
              exact: true
            },
          ],
        },
        {
          icon: 'mdi-comment-search-outline',
          key: '$menu.$myNotifications.title',
          text: 'My notifications',
          routeName: 'my-notifications-admin',
          perm: ['ROLE_ADMIN', 'ROLE_OFFICE_WORKER'],
          exact: true
        },
        {
          icon: 'mdi-translate',
          key: '$menu.languages',
          text: 'Languages',
          routeName: 'languages-admin',
          perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_LANGUAGE_LIST'],
          exact: true
        },
        {
          icon: 'mdi-file-document-outline',
          key: '$menu.$formsAndTemplates.title',
          text: 'Forms and Templates',
          perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_FORM_LIST', 'PERM_OFFICE_WORKER_FORM_CATEGORY_LIST'],
          items: [
            {
              key: '$menu.$formsAndTemplates.$formsAndTemplates.title',
              text: 'Forms and Templates',
              routeName: 'forms-and-templates-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_FORM_LIST'],
              exact: true
            },
            {
              key: '$menu.$formsAndTemplates.$categories.title',
              text: 'Categories',
              routeName: 'forms-and-templates-categories-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_FORM_CATEGORY_LIST'],
              exact: true
            },
          ],
        },
        {
          icon: 'mdi-credit-card-outline',
          key: '$menu.$servicesAndPayments.title',
          text: 'Services nad payments',
          perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_SERVICE_LIST', 'PERM_OFFICE_WORKER_COMPANY_BALANCE_LIST', 'PERM_OFFICE_WORKER_PAYMENT_LIST'],
          items: [
            {
              key: '$menu.$servicesAndPayments.$services.title',
              text: 'Services',
              routeName: 'services-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_SERVICE_LIST'],
              exact: true
            },
            {
              key: '$menu.$servicesAndPayments.$companyCart.title',
              text: 'User Cart',
              routeName: 'company-cart-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_COMPANY_BALANCE_LIST'],
              exact: true
            },
            {
              key: '$menu.$servicesAndPayments.$payments.title',
              text: 'Payments',
              routeName: 'payments-history-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_PAYMENT_LIST'],
              exact: true
            },
          ],
        },
        {
          icon: 'mdi-tools',
          key: '$menu.$utils.title',
          text: 'Utils',
          perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_UTILS_EXCHANGE_RATES', 'PERM_OFFICE_WORKER_UTILS_DICTIONARIES'],
          items: [
            {
              key: '$menu.$utils.$exchangeRates.title',
              text: 'Exchange rates',
              routeName: 'utils-exchange-rates-admin',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_UTILS_EXCHANGE_RATES'],
              exact: true
            },
            {
              key: '$menu.$utils.$dictionaries.title',
              text: 'Exchange rates',
              perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_UTILS_DICTIONARIES'],
              items: [
                {
                  key: '$menu.$utils.$dictionaries.vatExemption',
                  text: 'Exchange rates',
                  routeName: 'utils-dictionaries-vat-exemption-admin',
                  perm: ['ROLE_ADMIN', 'PERM_OFFICE_WORKER_UTILS_DICTIONARIES'],
                  exact: true
                }
              ]
            }
          ],
        },
        // USER
        {
          icon: 'mdi-calendar-check-outline',
          key: '$menu.myAccounting',
          text: 'My accounting',
          routeName: 'my-accounting-user',
          perm: 'PERM_BUSINESS_USER_MY_ACCOUNTING_LIST',
          exact: true
        },
        {
          icon: 'mdi-file-document-outline',
          key: '$menu.invoices',
          text: 'Invoices',
          routeName: 'invoices-user',
          perm: 'PERM_BUSINESS_USER_INVOICE_LIST',
          exact: true
        },
        {
          icon: 'mdi-account-multiple-outline',
          key: '$menu.clients',
          text: 'Clients',
          routeName: 'clients-user',
          perm: 'PERM_BUSINESS_USER_CLIENT_LIST',
          exact: true
        },
        {
          icon: 'mdi-package-variant-closed',
          key: '$menu.products',
          text: 'Products and services',
          routeName: 'products-user',
          perm: 'PERM_BUSINESS_USER_PRODUCT_LIST',
          exact: true
        },
        {
          icon: 'mdi-forum-outline',
          key: '$menu.ticketCenterUser',
          text: 'Messages (Q&A)',
          routeName: 'ticket-center-user',
          perm: 'PERM_BUSINESS_USER_TICKET_CENTER_USER_LIST',
          exact: true
        },
        {
          icon: 'mdi-comment-search-outline',
          key: '$menu.$myNotifications.title',
          text: 'My notifications',
          routeName: 'my-notifications-user',
          perm: 'ROLE_BUSINESS_USER',
          exact: true
        },
        {
          icon: 'mdi-truck-outline',
          key: '$menu.orderCourier',
          text: 'Order Courier',
          routeName: 'delivery-user',
          perm: 'PERM_BUSINESS_USER_DELIVERY',
          exact: true
        },
        {
          icon: 'mdi-file-document-outline',
          key: '$menu.$formsAndTemplates.$formsAndTemplates.title',
          text: 'Forms and Templates',
          routeName: 'forms-and-templates-user',
          perm: 'PERM_BUSINESS_USER_FORM_LIST',
          exact: true
        },
        {
          icon: 'mdi-lifebuoy',
          key: '$menu.help',
          text: 'Help',
          link: process.env.VUE_APP_HELP_URL,
          perm: 'ROLE_USER',
          exact: true
        },
      ],
    }
  }
}

